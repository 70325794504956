import { Route } from 'react-router-hoc';
import { lazy } from 'react';
import { idRegEx } from 'utils/regex';
import { DateRangeTypes } from 'types';

export const BillingRoute = Route(
  {
    id: Route.params.regex(idRegEx).optional,
    search: Route.query.string,
    status: Route.query.string,
    start: Route.query.string,
    end: Route.query.string,
    range: Route.query.oneOf(...Object.values(DateRangeTypes)),
  },
  ({ id }) => `/billing/${id}`,
);

export const Billing = BillingRoute(lazy(() => import(/* webpackChunkName: "Milestones" */ './Billing')));
