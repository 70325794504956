import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AbsoluteSpinner,
  AssignmentsCell,
  Avatar,
  Collapse,
  DropdownType,
  EmptyState,
  EndDateReminder,
  ExpandedColumn,
  ProjectInfoCell,
  SIZES,
  Table,
  TableActionCell,
  TableColumnSettingDrawer,
  Tooltip,
} from 'components';
import { GroupByFilter, TableCell } from 'types';
import {
  getAcronym,
  groupProjectsByName,
  groupProjectsByPM,
  groupProjectsByType,
  replaceAmountWithHiddenSymbols,
  sortByField,
  toShortFormat,
} from 'utils';
import {
  ACTIONS,
  ACTIVE_ASSIGNMENTS,
  ASC,
  CLIENT,
  CURRENT_COMMISSION,
  EMPTY_DATA_STATE,
  END_DATE,
  FILE,
  FIRST_NAME,
  FORECAST_MARGIN,
  LAST_NAME,
  MARGIN,
  PM,
  PROJECT,
  ProjectsTableColumns,
  START_DATE,
  TYPE,
} from 'consts';
import { links } from 'App';
import { ActionsType, ManagedProjectDataFragment } from 'generated/types';
import { useIsOpen, usePermissions, useProjectsTableSetting, useProjectTableSorts } from 'hooks';
import { SortingRule } from 'react-table';
import { FileCell } from 'views/Projects/components/FileCell';
import { roundPercent } from 'views/Insights/helpers';
import clsx from 'clsx';
import { ProjectManagerCell } from '../ProjectManagerCell';
import { IconButton } from '@material-ui/core';
import { SettingsActiveLink as SettingsIcon } from 'icons';
import { useSettings } from 'contexts';

import styles from './styles.module.scss';

export type ProjectData = ManagedProjectDataFragment & {
  margin: number;
  forecastMargin: number;
  currentCommission?: number | null;
  activeAssignments?: number;
};
interface Props {
  projects: ProjectData[];
  groupBy?: GroupByFilter;
  getTableActions: (id: string, isActiveAssignment: boolean) => ReactNode;
}

export const ProjectsTable: FC<Props> = ({ projects, groupBy, getTableActions }) => {
  const { t } = useTranslation();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const [tableSortBy, setTableSortBy] = useState<SortingRule<ProjectData>[] | null>(null);
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { isFinancialsHidden } = useSettings();

  const changeTableSortBy = useCallback((sortBy: SortingRule<ProjectData>) => {
    setTableSortBy([sortBy]);
  }, []);

  const groupedProjectsByClient = groupBy === GroupByFilter.client ? groupProjectsByName(projects) : {};
  const groupedProjectsByPM =
    groupBy === GroupByFilter.pm || groupBy === GroupByFilter.projectManager ? groupProjectsByPM(projects) : {};
  const groupedProjectsByType =
    groupBy === GroupByFilter.type || groupBy === GroupByFilter.projectType ? groupProjectsByType(projects) : {};

  const {
    defaultActiveColumns,
    columnsOrder,
    onChangeColumnsOrder,
    activeColumns,
    onChangeActiveColumns,
  } = useProjectsTableSetting();

  const { sortNameType, sortClientType, sortStartDateType, sortEndDateType, sortPMType } = useProjectTableSorts();

  const columns = useMemo<ExpandedColumn<ProjectData>[]>(() => {
    const isGroupColumns = groupBy && groupBy !== GroupByFilter.none;

    return [
      {
        id: 'project',
        Header: <span className={isGroupColumns ? 'pl-30' : 'pl-0'}>{t('columns.projects.project')}</span>,
        accessor: ({ name, color, client }) => ({ name, color, client }),
        sticky: 'left',
        sortable: true,
        sortType: sortNameType,
        Cell: function project({ value: { name, color } }: TableCell<Pick<ProjectData, 'name' | 'color'>>) {
          return (
            <ProjectInfoCell
              hasTooltip
              hasSubtitleTooltip
              title={name}
              color={color}
              className={clsx(styles.containerProjectCell, groupBy && groupBy !== GroupByFilter.none && 'pl-30')}
              titleClassName={styles.containerProjectTitle}
            />
          );
        },
      },
      {
        Header: t('columns.projects.client')!,
        accessor: CLIENT,
        sortable: true,
        sortType: sortClientType,
        show: activeColumns.includes(ProjectsTableColumns.Client) && groupBy !== GroupByFilter.client,
        Cell: function client({ value }) {
          return (
            <Tooltip title={value?.name || ''} placement="top" textClassName={styles.containerProjectTitle}>
              {value?.name || EMPTY_DATA_STATE}
            </Tooltip>
          );
        },
      },
      {
        Header: t('columns.projects.projectType')!,
        accessor: TYPE,
        sortable: true,
        show: activeColumns.includes(ProjectsTableColumns.Type) && groupBy !== GroupByFilter.projectType,
        Cell: function type({ value }) {
          return t(`projectType.${value}`);
        },
        minWidth: 150,
      },
      {
        Header: t('columns.projects.startDate')!,
        accessor: START_DATE,
        sortable: true,
        sortType: sortStartDateType,
        show: activeColumns.includes(ProjectsTableColumns.StartDate),
        Cell: function endDate({ value }) {
          return toShortFormat(new Date(value));
        },
      },
      {
        Header: t('columns.projects.endDate')!,
        accessor: END_DATE,
        sortable: true,
        sortType: sortEndDateType,
        show: activeColumns.includes(ProjectsTableColumns.EndDate),
        Cell: function endDate({ value }) {
          return <EndDateReminder date={value} hideTag />;
        },
      },
      {
        Header: t('columns.projects.projectManager')!,
        accessor: PM,
        sortable: true,
        sortType: sortPMType,
        show: activeColumns.includes(ProjectsTableColumns.PM) && groupBy !== GroupByFilter.projectManager,
        Cell: function projectManager({
          row: {
            original: { id, pm, type },
          },
        }) {
          return <ProjectManagerCell projectId={id} pm={pm} type={type} />;
        },
      },
      {
        Header: t('columns.projects.files')!,
        id: FILE,
        sortable: true,
        accessor: 'countFiles',
        show: activeColumns.includes(ProjectsTableColumns.File),
        Cell: function file({
          row: {
            original: { id, countFiles },
          },
        }) {
          return <FileCell projectId={id} activeItemsNumber={countFiles} />;
        },
      },
      {
        Header: t('columns.projects.team')!,
        sortable: true,
        accessor: ACTIVE_ASSIGNMENTS,
        show: activeColumns.includes(ProjectsTableColumns.ActiveAssignments),
        Cell: function team({
          row: {
            original: { id, activeAssignments },
          },
        }) {
          return <AssignmentsCell projectId={id} activeItemsNumber={activeAssignments} type={DropdownType.team} />;
        },
      },
      {
        Header: t('columns.projects.ytdMargin')!,
        accessor: MARGIN,
        sortable: true,
        show: hasAccess(ActionsType.ViewProjectCosts) && activeColumns.includes(ProjectsTableColumns.Margin),
        Cell: function margin({ value }) {
          return (
            <>
              {typeof value === 'number'
                ? `${replaceAmountWithHiddenSymbols(roundPercent(value), isFinancialsHidden)}%`
                : EMPTY_DATA_STATE}
            </>
          );
        },
      },
      {
        Header: <span className="w-max-cont">{t('columns.projects.yearForecastMargin')}</span>,
        accessor: FORECAST_MARGIN,
        sortable: true,
        show: hasAccess(ActionsType.ViewProjectCosts) && activeColumns.includes(ProjectsTableColumns.ForecastMargin),
        Cell: function forecastMargin({ value }) {
          return (
            <span className={styles.forecastedMargin}>
              {typeof value === 'number'
                ? `${replaceAmountWithHiddenSymbols(roundPercent(value), isFinancialsHidden)}%`
                : EMPTY_DATA_STATE}
            </span>
          );
        },
      },
      {
        Header: <span className="w-max-cont">{t('columns.projects.currentCommission')}</span>,
        accessor: CURRENT_COMMISSION,
        sortable: true,
        show: hasAccess(ActionsType.ViewProjectCosts) && activeColumns.includes(ProjectsTableColumns.CurrentCommission),
        Cell: function currentCommission({ value }) {
          return (
            <span className={styles.forecastedMargin}>
              {typeof value === 'number'
                ? `${replaceAmountWithHiddenSymbols(roundPercent(value), isFinancialsHidden)}%`
                : EMPTY_DATA_STATE}
            </span>
          );
        },
      },
      {
        Header: (
          <Tooltip
            title={t('table.columnSettings.customiseColumns')!}
            placement="top"
            className={styles.settingTooltip}
            alwaysShowTooltip
          >
            <IconButton size="small" className="p-0" onClick={onOpen}>
              <SettingsIcon className={styles.settingIcon} />
            </IconButton>
          </Tooltip>
        ),
        id: ACTIONS,
        accessor: 'id',
        Cell: function action({
          row: {
            original: { id, activeAssignments },
          },
          isHovered,
        }) {
          return hasAccess(ActionsType.ArchiveProjects) ? (
            <TableActionCell isHovered={isHovered}>{getTableActions(id, !!activeAssignments)}</TableActionCell>
          ) : (
            ''
          );
        },
      },
    ];
  }, [groupBy, isPermissionsLoading, activeColumns, isFinancialsHidden]);

  const getRedirectToDetailsLink = (rowId: string) => links.ProjectDetail({ id: rowId });

  if (isPermissionsLoading) {
    return <AbsoluteSpinner />;
  }

  if (!projects.length) {
    return <EmptyState className={styles.emptyState} />;
  }

  if (!hasAccess(ActionsType.ViewActiveProjects)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  const renderTable = (data: ProjectData[], insideAccordion?: boolean) => (
    <div className={styles.containerTable}>
      <Table
        columns={columns}
        getRedirectRowLink={getRedirectToDetailsLink}
        data={data}
        onChangeTableSortBy={changeTableSortBy}
        sortByValue={tableSortBy}
        columnOrder={[ProjectsTableColumns.Project, ...columnsOrder]}
        insideAccordion={insideAccordion}
      />
    </div>
  );

  return (
    <>
      {!groupBy || groupBy === GroupByFilter.none ? renderTable(sortByField(projects, ASC, PROJECT)) : ''}
      {groupBy === GroupByFilter.client ? (
        <>
          {Object.keys(groupedProjectsByClient).map((key) => {
            const projectsAmount = groupedProjectsByClient[key].length;
            const title = projectsAmount ? groupedProjectsByClient[key][0].client?.name ?? t(`projects.${key}`)! : '';

            return (
              <div key={key} className={styles.container}>
                <Collapse
                  header={
                    <>
                      <Tooltip title={title} placement="top" textClassName={styles.containerTitle}>
                        {title}
                      </Tooltip>
                      <span className={styles.containerChip}>{projectsAmount}</span>
                    </>
                  }
                  withBorders
                >
                  {renderTable(sortByField(groupedProjectsByClient[key], ASC, PROJECT) as ProjectData[], true)}
                </Collapse>
              </div>
            );
          })}
        </>
      ) : (
        ''
      )}
      {groupBy === GroupByFilter.pm || groupBy === GroupByFilter.projectManager ? (
        <>
          {Object.keys(groupedProjectsByPM).map((key) => {
            const projectsAmount = groupedProjectsByPM[key].length;
            const { first_name, last_name, color } = groupedProjectsByPM[key][0].pm;
            const title = projectsAmount ? `${first_name} ${last_name}` ?? t(`projects.${key}`)! : '';

            return (
              <div key={key} className={styles.container}>
                <Collapse
                  header={
                    <>
                      <Avatar avatarTitle={getAcronym(first_name, last_name)} size={SIZES.sm} color={color} />
                      <Tooltip title={title} placement="top" textClassName={styles.containerTitle}>
                        {title}
                      </Tooltip>
                      <span className={styles.containerChip}>{projectsAmount}</span>
                    </>
                  }
                  withBorders
                >
                  {renderTable(
                    sortByField(groupedProjectsByPM[key], ASC, FIRST_NAME, LAST_NAME) as ProjectData[],
                    true,
                  )}
                </Collapse>
              </div>
            );
          })}
        </>
      ) : (
        ''
      )}

      {groupBy === GroupByFilter.type || groupBy === GroupByFilter.projectType ? (
        <>
          {Object.keys(groupedProjectsByType).map((key) => {
            const projectsAmount = groupedProjectsByType[key].length;
            const type = groupedProjectsByType[key][0].type;
            const title = projectsAmount ? t(`projectType.${type}`) ?? t(`projects.${key}`)! : '';

            return (
              <div key={key} className={styles.container}>
                <Collapse
                  header={
                    <>
                      <Tooltip title={title} placement="top" textClassName={styles.containerTitle}>
                        {title}
                      </Tooltip>
                      <span className={styles.containerChip}>{projectsAmount}</span>
                    </>
                  }
                  withBorders
                >
                  {renderTable(sortByField(groupedProjectsByType[key], ASC, TYPE) as ProjectData[], true)}
                </Collapse>
              </div>
            );
          })}
        </>
      ) : (
        ''
      )}

      <TableColumnSettingDrawer
        getColumnTitle={(column) => t(`projects.columnSettings.${column}`)}
        allColumnOptions={defaultActiveColumns}
        activeColumns={activeColumns}
        columnsOrder={columnsOrder}
        onSubmit={onChangeActiveColumns}
        onChangeOrder={onChangeColumnsOrder}
        open={isOpen}
        onClose={onClose}
      />
    </>
  );
};
