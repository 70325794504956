import React, { useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { debounce } from '@material-ui/core';

import { links } from 'App';
import { ResizableSearch } from 'components';
import { useQueryParams } from 'utils/useQueryParams';
import { Filters } from '../../types';

import styles from './styles.module.scss';

export const SearchField = () => {
  const { search, availability, membersType, ...query } = useQueryParams<Filters>();
  const { t } = useTranslation();

  const { params } = useRouteMatch<{ type?: 'project' }>();
  const { push } = useHistory();
  const [searchQuery, setSearchQuery] = useState(search ?? '');

  window.addEventListener('keydown', function (e) {
    if (e.keyCode === 114 || ((e.ctrlKey || e.metaKey) && e.keyCode === 70)) {
      const searchElement = document.getElementById('resizable-search');

      if (searchElement && searchElement !== document.activeElement) {
        e.preventDefault();
        searchElement?.focus();
      } else {
        return true;
      }
    }
  });

  const setQueryParams = useCallback(
    debounce((queryParams: Filters) => {
      return push(links.ResourcePlanning({ availability, membersType, ...params, ...query, ...queryParams }));
    }, 200),
    [push, params, availability, membersType],
  );

  const onSearch = useCallback(
    (value) => {
      setSearchQuery(value);
      setQueryParams({ search: value });
    },
    [setQueryParams],
  );

  return (
    <div className={styles.searchCell}>
      <ResizableSearch
        className={styles.search}
        onChange={onSearch}
        value={searchQuery}
        placeholder={t(params.type === 'project' ? 'forms.searchProject' : 'forms.searchMember')}
        fixed
      />
    </div>
  );
};
