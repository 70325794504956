import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getLinks } from 'react-router-hoc';
import mixpanel from 'mixpanel-browser';

import { useDeviceTypeByWidth, useSetupTracking } from 'hooks';
import { useAuth, useSettings } from 'contexts';
import { BaseLayout, EmployeeBaseLayout, MobileBaseLayout, UnauthLayout } from 'layouts';
import { SlideSwitch } from 'components';
import { TeamTabs } from 'types';
import { MemberType } from 'generated/types';
import { CircularProgress } from '@material-ui/core';
import {
  ActiveProjects,
  ActiveTeamMembers,
  Activity,
  ArchivedProjects,
  ArchivedTeamMembers,
  Assignments,
  Billing,
  CompleteProfile,
  EmployeeTimeTracking,
  Expenses,
  Files,
  Financial,
  ForgotPass,
  Holidays,
  Insights,
  Integrations,
  Login,
  MemberTimeTracking,
  NotFound,
  Payroll,
  ProjectDetail,
  ProjectRoles,
  Projects,
  ResetPassword,
  ResourcePlanning,
  ResourceRequest,
  ScenarioDetails,
  Scenarios,
  Seniority,
  Settings,
  Skills,
  Specialization,
  Teams,
  Users,
  ViewPayrollRecord,
  ViewTeamMember,
} from 'views';

import styles from './App.module.scss';

const spinnerColor = '#24343D';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!, {
  disable_persistence: true,
  opt_out_tracking_by_default: process.env.NODE_ENV !== 'production',
});

export const links = getLinks({
  Teams,
  Projects,
  ResourcePlanning,
  ResourceRequest,
  Expenses,
  Settings,
  Users,
  NotFound,
  Login,
  ResetPassword,
  ForgotPass,
  CompleteProfile,
  ViewTeamMember,
  MemberTimeTracking,
  ProjectDetail,
  Financial,
  Skills,
  Assignments,
  EmployeeTimeTracking,
  ActiveTeamMembers,
  ArchivedTeamMembers,
  ActiveProjects,
  ArchivedProjects,
  Specialization,
  ProjectRoles,
  Seniority,
  Holidays,
  Payroll,
  ViewPayrollRecord,
  Integrations,
  Scenarios,
  ScenarioDetails,
  Files,
  Activity,
  Insights,
  Billing,
});

const AuthRoutes = () => (
  <BaseLayout>
    <Suspense
      fallback={
        <div className={styles.loader}>
          <CircularProgress size={64} style={{ color: spinnerColor }} />
        </div>
      }
    >
      <SlideSwitch>
        <Teams />
        <Projects />
        <ResourcePlanning />
        <Scenarios exact />
        <ScenarioDetails />
        <ResourceRequest />
        <Expenses />
        <Settings />
        <ViewTeamMember />
        <ProjectDetail />
        <Files />
        <Billing />
        <Insights />
        <Activity />
        <ViewPayrollRecord />
        <Payroll />
        <Redirect to={links.ResourcePlanning()} />
      </SlideSwitch>
    </Suspense>
  </BaseLayout>
);

const MobileAuthRoutes = () => (
  <MobileBaseLayout>
    <Suspense
      fallback={
        <div className={styles.loader}>
          <CircularProgress size={64} style={{ color: spinnerColor }} />
        </div>
      }
    >
      <SlideSwitch>
        <Teams />
        <Projects />
        <ResourceRequest />
        <ViewTeamMember />
        <ProjectDetail />
        <Files />
        <Activity />
        <Redirect to={links.Teams({ tab: TeamTabs.active })} />
      </SlideSwitch>
    </Suspense>
  </MobileBaseLayout>
);

const EmployeeAuthRoutes = () => (
  <EmployeeBaseLayout>
    <Suspense
      fallback={
        <div className={styles.loader}>
          <CircularProgress size={64} style={{ color: spinnerColor }} />
        </div>
      }
    >
      <SlideSwitch>
        <EmployeeTimeTracking />
        <Redirect to={links.EmployeeTimeTracking()} />
      </SlideSwitch>
    </Suspense>
  </EmployeeBaseLayout>
);

const UnauthRoutes = () => (
  <UnauthLayout>
    <Switch>
      <Login />
      <ResetPassword />
      <ForgotPass />
      <CompleteProfile />
      <Redirect to={links.NotFound()} />
    </Switch>
  </UnauthLayout>
);

const App = () => {
  const { isInitializing, isAuthenticated, userData } = useAuth();
  const { isEmployeeMode } = useSettings();
  const { isMobileDevice } = useDeviceTypeByWidth();
  useSetupTracking();

  if (isInitializing) {
    return (
      <UnauthLayout>
        <CircularProgress size={64} style={{ color: spinnerColor }} />
      </UnauthLayout>
    );
  }

  const generalAuthRoutes = isMobileDevice ? <MobileAuthRoutes /> : <AuthRoutes />;
  const employeeAuthRoutes = <EmployeeAuthRoutes />;
  const authRoutes = isEmployeeMode || userData?.type === MemberType.Employee ? employeeAuthRoutes : generalAuthRoutes;
  const afterLoginRedirectLink = isMobileDevice ? links.ActiveTeamMembers() : links.ResourcePlanning();

  return (
    <Switch>
      <Route path={links.NotFound()}>
        <NotFound />
      </Route>

      <Redirect exact from="/" to={isAuthenticated ? links.ResourcePlanning() : links.Login()} />

      <Route path={[links.ForgotPass(), links.Login(), links.ResetPassword(), links.CompleteProfile()]}>
        {isAuthenticated ? <Redirect to={afterLoginRedirectLink} /> : <UnauthRoutes />}
      </Route>

      {isAuthenticated ? authRoutes : <Redirect to={links.Login()} />}
    </Switch>
  );
};

export default App;
