import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SortingRule } from 'react-table';

import {
  AssignmentsCell,
  DropdownType,
  MemberIntegrationStatus,
  Table,
  TableActionCell,
  TableColumnSettingDrawer,
  Tooltip,
  UserInfo,
} from 'components';
import {
  ACTIONS,
  ACTIVE_ASSIGNMENTS,
  EMAIL,
  EMPTY_DATA_STATE,
  EXIT_DATE,
  INTEGRATION_STATUS,
  JOB_TITLE,
  JOIN_DATE,
  NAME,
  REPORTING_TO,
  SENIORITY,
  SPECIALIZATION,
  TeamTableColumns,
  TYPE,
} from 'consts';
import { TableCell } from 'types';
import { formatAmount, getAcronym, getFullName } from 'utils';
import { EmploymentType, Member, MemberIntegrationStatus as MemberIntegrationStatusType } from 'generated/types';
import { SettingsActiveLink as SettingsIcon } from 'icons';
import { useIsOpen, useMemberTableSorts, useTeamTableSetting } from 'hooks';
import { links } from 'App';
import { useSettings } from 'contexts';
import { IconButton } from '@material-ui/core';
import { ReportingToCell } from 'views/Teams/components/ReportingToCell';
import { format } from 'date-fns';

import styles from './styles.module.scss';

export type MemberData = Member & {
  integrationStatuses: MemberIntegrationStatusType[];
  activeAssignments: number;
  currentSalary: string;
};

interface Props {
  members: MemberData[];
  showTableActions?: boolean;
  getTableActions: (id: string) => ReactNode;
}

export const TeamTable: FC<Props> = ({ members, showTableActions, getTableActions }) => {
  const { t } = useTranslation();
  const [tableSortBy, setTableSortBy] = useState<SortingRule<MemberData>[] | null>(null);
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { isFinancialsHidden } = useSettings();

  const changeTableSortBy = useCallback((sortBy: SortingRule<MemberData>) => {
    if (sortBy.id) {
      setTableSortBy([sortBy]);
    }
  }, []);

  const {
    allColumnsOptions,
    defaultActiveColumns,
    columnsOrder,
    onChangeColumnsOrder,
    activeColumns,
    onChangeActiveColumns,
  } = useTeamTableSetting();

  const {
    sortTitleType,
    sortMemberType,
    sortSeniorityMemberType,
    sortReportingToType,
    sortSpecializationMemberType,
    sortTypeMemberType,
    sortDateJoiningType,
    sortDateExitType,
  } = useMemberTableSorts();

  const columns = useMemo(
    () => [
      {
        Header: t('columns.teams.name'),
        accessor: NAME,
        sticky: 'left',
        sortable: true,
        sortType: sortMemberType,
        Cell: function name({
          row: {
            original: { first_name, last_name, employment_type, color },
          },
        }: TableCell<Member>) {
          return (
            <UserInfo
              avatarTitle={getAcronym(first_name, last_name)}
              titleClassName={styles.userTitle}
              showIndicator={employment_type === EmploymentType.Contractor}
              title={getFullName(first_name, last_name, t('notApplicable'))}
              color={color}
            />
          );
        },
      },
      {
        Header: t('columns.teams.jobTitle'),
        accessor: JOB_TITLE,
        sortable: true,
        sortType: sortTitleType,
        show: activeColumns.includes(TeamTableColumns.JobTitle),
        Cell: function jobTitle({
          row: {
            original: { job_title },
          },
        }: TableCell<Member>) {
          return <span className="weight-600 w-max-cont">{job_title || EMPTY_DATA_STATE}</span>;
        },
      },
      {
        Header: t('columns.teams.email'),
        accessor: EMAIL,
        sortable: true,
        show: activeColumns.includes(TeamTableColumns.Email),
        Cell: function email({
          row: {
            original: { email },
          },
        }: TableCell<Member>) {
          return <span className="weight-600">{email || EMPTY_DATA_STATE}</span>;
        },
      },
      {
        Header: t('columns.teams.type'),
        accessor: TYPE,
        sortable: true,
        sortType: sortTypeMemberType,
        show: activeColumns.includes(TeamTableColumns.Type),
        Cell: function employmentType({
          row: {
            original: { employment_type },
          },
        }: TableCell<Member>) {
          return (
            <span
              className={clsx(
                styles.employmentTypeLabel,
                employment_type === EmploymentType.Contractor && styles.employmentTypeLabelContractor,
              )}
            >
              {employment_type || EMPTY_DATA_STATE}
            </span>
          );
        },
      },
      {
        Header: t('columns.teams.reportingTo'),
        accessor: REPORTING_TO,
        sortable: true,
        sortType: sortReportingToType,
        show: activeColumns.includes(TeamTableColumns.ReportingTo),
        Cell: function reportingTo({
          row: {
            original: { id, reportingTo },
          },
        }: TableCell<Member>) {
          return <ReportingToCell memberId={id} reportingTo={reportingTo!} />;
        },
      },
      {
        Header: t('columns.teams.specialization'),
        accessor: SPECIALIZATION,
        sortable: true,
        sortType: sortSpecializationMemberType,
        show: activeColumns.includes(TeamTableColumns.Specialization),
        Cell: function specialization({
          row: {
            original: { memberSpecialization },
          },
        }: TableCell<Member>) {
          return <span className="weight-600 w-max-cont">{memberSpecialization ?? EMPTY_DATA_STATE}</span>;
        },
      },
      {
        Header: t('columns.teams.seniority'),
        accessor: SENIORITY,
        sortable: true,
        sortType: sortSeniorityMemberType,
        show: activeColumns.includes(TeamTableColumns.Seniority),
        Cell: function specialization({
          row: {
            original: { member_seniority },
          },
        }: TableCell<Member>) {
          return <span className="weight-600 w-max-cont">{member_seniority?.name ?? EMPTY_DATA_STATE}</span>;
        },
      },
      {
        Header: t('columns.teams.projects')!,
        sortable: true,
        accessor: ACTIVE_ASSIGNMENTS,
        show: activeColumns.includes(TeamTableColumns.ActiveAssignments),
        Cell: function projects({
          row: {
            original: { id, activeAssignments },
          },
        }: TableCell<MemberData>) {
          return (
            <AssignmentsCell memberId={id} activeItemsNumber={activeAssignments || 0} type={DropdownType.projects} />
          );
        },
      },
      {
        Header: t('columns.teams.integrationStatus'),
        accessor: INTEGRATION_STATUS,
        show: activeColumns.includes(TeamTableColumns.IntegrationStatus),
        Cell: function integrationStatuses({ row: { original } }: TableCell<MemberData>) {
          return (
            <div className="flex flex-column gap-2">
              {original.integrationStatuses.map(({ integrationName, status }) => (
                <MemberIntegrationStatus key={integrationName} integrationName={integrationName} status={status} />
              ))}
            </div>
          );
        },
      },
      {
        Header: t('columns.teams.currentSalary'),
        accessor: 'currentSalary',
        sortable: true,
        show: activeColumns.includes(TeamTableColumns.CurrentSalary),
        Cell: function specialization({
          row: {
            original: { currentSalary },
          },
        }: TableCell<MemberData>) {
          const salary = currentSalary ? parseInt(currentSalary.match(/\d+/)![0], 10) : '';
          const salaryText = currentSalary ? currentSalary.replace(/\d+/, '') : '';

          return (
            <span className="weight-600 w-max-cont">
              {salary ? `${formatAmount(salary, isFinancialsHidden)}${salaryText}` : EMPTY_DATA_STATE}
            </span>
          );
        },
      },
      {
        Header: t('columns.teams.join_date'),
        accessor: JOIN_DATE,
        sortable: true,
        sortType: sortDateJoiningType,
        show: activeColumns.includes(TeamTableColumns.JoiningDate),
        Cell: function joiningDate({ value }: TableCell<Date>) {
          return (
            <span className="weight-600 w-max-cont">
              {value ? format(new Date(value), 'LLL d, yyyy') : EMPTY_DATA_STATE}
            </span>
          );
        },
      },
      {
        Header: t('columns.teams.exit_date'),
        accessor: EXIT_DATE,
        sortable: true,
        sortType: sortDateExitType,
        show: activeColumns.includes(TeamTableColumns.ExitDate),
        Cell: function exitDate({ value }: TableCell<Date>) {
          return (
            <span className="weight-600 w-max-cont">
              {value ? format(new Date(value), 'LLL d, yyyy') : EMPTY_DATA_STATE}
            </span>
          );
        },
      },
      {
        Header: (
          <Tooltip
            title={t('table.columnSettings.customiseColumns')!}
            className={styles.settingTooltip}
            placement="top"
            alwaysShowTooltip
          >
            <IconButton size="small" className="p-0" onClick={onOpen}>
              <SettingsIcon className={styles.settingIcon} />
            </IconButton>
          </Tooltip>
        ),
        accessor: ACTIONS,
        Cell: function action({
          row: {
            original: { id },
          },
          isHovered,
        }: TableCell<Member> & { isHovered?: boolean }) {
          return showTableActions ? <TableActionCell isHovered={isHovered}>{getTableActions(id)}</TableActionCell> : '';
        },
      },
    ],
    [showTableActions, activeColumns, isFinancialsHidden],
  );

  const getRedirectToDetailsLink = (rowId: string) => links.ViewTeamMember({ id: rowId });

  return (
    <>
      <Table
        getRedirectRowLink={getRedirectToDetailsLink}
        data={members}
        columns={columns as any}
        onChangeTableSortBy={changeTableSortBy}
        sortByValue={tableSortBy}
        columnOrder={[TeamTableColumns.Name, ...columnsOrder]}
      />

      <TableColumnSettingDrawer
        getColumnTitle={(column) => t(`teams.columnSettings.${column}`)}
        allColumnOptions={allColumnsOptions}
        defaultShownColumns={defaultActiveColumns}
        activeColumns={activeColumns}
        columnsOrder={columnsOrder}
        onSubmit={onChangeActiveColumns}
        onChangeOrder={onChangeColumnsOrder}
        open={isOpen}
        onClose={onClose}
      />
    </>
  );
};
