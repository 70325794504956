import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

import { ACTIONS, CREATION_DATE, EMAIL, EMPTY_DATA_STATE, FIRST_NAME, PROJECT_ACCESS, ROLE, STATUS } from 'consts';
import {
  Badge,
  Colors,
  ConfirmIconButton,
  EmptyState,
  ResizableSearch,
  RightDrawer,
  Table,
  TableActionCell,
  UserInfo,
} from 'components';
import { GetRouteProps, ModalModeEnum, ModuleName, ScreenName, TableCell } from 'types';
import {
  getAcronym,
  getFullName,
  graphqlOnError,
  isAdmin,
  isAdminPermission,
  isOwner,
  searchFilter,
  toShortFormat,
} from 'utils';
import {
  useDeactivateUserMutation,
  useDeleteInvitationMutation,
  useEditMemberMutation,
  useInvitationsQuery,
  useInviteUserMutation,
  useRefreshInvitationMutation,
  useRestoreUserMutation,
  useUsersQuery,
} from 'generated/graphql';
import { Column } from 'react-table';
import {
  ActionsType,
  CompanyUser,
  CompanyUserDataFragment,
  CompanyUserStatus,
  EditableMemberType,
  Invitation,
  MemberInvitationStatus,
  MemberType,
} from 'generated/types';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, usePermissions, useTrackScreenView } from 'hooks';
import { UsersBuilder } from 'utils/usersBuilder';
import type { UsersRoute } from './index';
import { ProjectAccessCell } from './ProjectAccessCell';
import pluralize from 'pluralize';
import { IconButton, Tooltip } from '@material-ui/core';
import { DeactivateUser, EditIcon, PlusIcon, Resend, RestoreUser } from 'icons';
import { NewUser, NewUserFormValues } from './NewUser';
import styles from './styles.module.scss';

type Props = GetRouteProps<typeof UsersRoute>;

const Users: FC<Props> = ({
  link,
  history: { push },
  match: {
    params,
    query: { search = '' },
  },
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(search);
  const [isActive, setIsActive] = useState(false);
  const { hasAccess, isPermissionsLoading } = usePermissions();
  useTrackScreenView(ModuleName.settings, ScreenName.users);

  const { data: { users = [] as CompanyUser[] } = {}, loading } = useUsersQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      withAccessCount: true,
    },
    fetchPolicy: 'cache-and-network',
    skip: !hasAccess(ActionsType.ViewUsers),
  });

  useEffect(() => {
    !searchTerm && onSearchQueryUpdate();
  }, [searchTerm]);

  const {
    data: { invitations = [] as Invitation[] } = {},
    loading: invitationLoading,
    refetch: refetchInvitations,
  } = useInvitationsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
    skip: !hasAccess(ActionsType.ViewInvitations),
  });

  const onCloseCreateModal = useCallback(() => {
    push(link({ ...params, mode: undefined }));
  }, [params]);

  const onCloseEditModal = useCallback(() => {
    push(link({ ...params, mode: undefined, userId: undefined }));
  }, [params]);

  const onSearchQueryUpdate = useCallback(
    () =>
      push(
        link({
          ...params,
          search: searchTerm ? searchTerm : undefined,
        }),
      ),
    [searchTerm, params],
  );

  const [inviteUserMutation] = useInviteUserMutation({
    async onCompleted() {
      toast.success(t('settings.inviteSentSuccess'));
      onCloseCreateModal();
      await refetchInvitations();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [refreshInvitation] = useRefreshInvitationMutation({
    onCompleted() {
      toast.success(t('settings.inviteResentSuccess'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteInvitation] = useDeleteInvitationMutation({
    onCompleted() {
      toast.success(t('settings.inviteDeleteSuccess'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteInvitation) });
        cache.gc();
      }
    },
  });

  const [updateMember] = useEditMemberMutation({
    onCompleted() {
      toast.success(t('settings.editSuccess'));
      onCloseEditModal();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deactivateUser] = useDeactivateUserMutation({
    async onCompleted() {
      toast.success(t('settings.userDeactivatedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [restoreUser] = useRestoreUserMutation({
    async onCompleted() {
      toast.success(t('settings.userActivatedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const filteredUsers = useMemo(() => {
    if (!users.length && !invitations.length) return [];

    const updatedUsersList = users.filter((user) => user.type !== MemberType.SuperAdmin);

    // TODO: find better approach
    const mappedInvitation = invitations
      .map(({ id, email, status, created_at }) => ({
        id,
        createdAt: created_at,
        status,
        member: {
          id,
          first_name: null,
          last_name: null,
          is_pending: status === MemberInvitationStatus.Created,
          email,
        },
      }))
      .filter(({ member: { is_pending } }) => is_pending);

    if (!searchTerm) {
      return [...updatedUsersList, ...mappedInvitation];
    }

    return [...updatedUsersList, ...mappedInvitation].filter(({ member: { first_name, last_name, email } }) =>
      searchFilter(searchTerm, first_name as string, last_name as string, email),
    );
  }, [users, invitations, searchTerm]);

  const activeUsers = useMemo(() => {
    const activatedUsers = new UsersBuilder(filteredUsers as CompanyUser[]);
    activatedUsers.sortMembersByName();
    activatedUsers.buildRestoredUsers();

    return activatedUsers.getUsers();
  }, [filteredUsers]);

  const allUsers = useMemo(() => {
    const activatedUsers = new UsersBuilder(filteredUsers as CompanyUser[]);
    activatedUsers.sortMembersByName();

    return activatedUsers.getUsers();
  }, [filteredUsers]);

  const getStatusBadgeColor = (isPending: boolean, status: CompanyUserStatus) => {
    if (isPending && status !== CompanyUserStatus.Deactivated) {
      return Colors.yellow;
    }

    switch (status) {
      case CompanyUserStatus.Active: {
        return Colors.emerald;
      }
      case CompanyUserStatus.Deactivated: {
        return Colors.gray;
      }
      default: {
        return Colors.gray;
      }
    }
  };

  const getUserStatus = (isPending: boolean, status: CompanyUserStatus) => {
    if (isPending && status !== CompanyUserStatus.Deactivated) {
      return t('badges.pending');
    }

    switch (status) {
      case CompanyUserStatus.Active: {
        return t('badges.active');
      }
      case CompanyUserStatus.Deactivated: {
        return t('badges.deactivated');
      }
      default: {
        return t(`badges.${status}`);
      }
    }
  };

  const isMe = useCallback((member: CompanyUser) => member.id === userData?.id, [userData]);

  const handleNewUserSubmit = async ({ email, role }: NewUserFormValues) => {
    userData &&
      (await inviteUserMutation({
        variables: {
          email,
          role: role!.id,
          companyId: userData.company.id,
        },
      }));
  };

  const memberToEdit = useMemo(() => {
    if (!params.userId) {
      return;
    }

    return users.find((user) => user.member.id === params.userId);
  }, [params.userId, users]);

  const handleMemberEditSubmit = async ({ role }: NewUserFormValues) => {
    if (!memberToEdit || !userData) return;

    if (role?.id && role.id.valueOf() !== memberToEdit.type.valueOf()) {
      const {
        member: { id: memberId },
      } = memberToEdit;

      await updateMember({
        variables: {
          companyId: userData?.company.id,
          role: role.id,
          memberId,
        },
      });
    }
  };

  const handleDeactivateUser = (user: CompanyUser) =>
    deactivateUser({
      variables: {
        companyId: userData?.company.id as string,
        memberId: user.id,
      },
    });

  const handleActivateUser = (id: string) =>
    restoreUser({
      variables: {
        companyId: userData?.company.id as string,
        memberId: id,
      },
    });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setIsActive(event.target.checked);

  const handleResendInvitation = ({ id }: CompanyUser) => {
    refreshInvitation({
      variables: {
        companyId: userData?.company.id as string,
        id,
      },
    });
  };

  const handleDeleteInvitation = ({ id }: CompanyUser) => {
    deleteInvitation({
      variables: {
        companyId: userData?.company.id as string,
        deleteInvitationId: id,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: t('columns.settings.userName'),
        accessor: FIRST_NAME,
        Cell: function name({
          row: {
            original: {
              member: { first_name, last_name, color },
            },
          },
        }: TableCell<CompanyUser>) {
          return (
            <UserInfo
              title={getFullName(first_name, last_name, t('notApplicable'))}
              titleClassName={styles.userTitle}
              avatarTitle={getAcronym(first_name, last_name)}
              color={color}
            />
          );
        },
      },
      {
        Header: t('columns.settings.userStatus'),
        accessor: STATUS,
        Cell: function status({
          row: {
            original: {
              member: { is_pending },
              status,
            },
          },
        }: TableCell<CompanyUser>) {
          return (
            <Badge color={getStatusBadgeColor(is_pending, status)} className={styles.badge}>
              <span className="capitalize">{getUserStatus(is_pending, status)}</span>
            </Badge>
          );
        },
      },
      {
        Header: t('columns.settings.email'),
        accessor: EMAIL,
        Cell: function status({
          row: {
            original: {
              member: { email },
            },
          },
        }: TableCell<CompanyUser>) {
          return <span className="weight-600">{email}</span>;
        },
      },
      {
        Header: t('columns.settings.permission'),
        accessor: ROLE,
        Cell: function role({
          row: {
            original: {
              type,
              member: { is_pending },
            },
          },
        }: TableCell<CompanyUser>) {
          return !is_pending ? t(`roles.${type}`) : '-';
        },
        minWidth: 170,
      },
      {
        id: PROJECT_ACCESS,
        Header: <span className="w-max-cont">{t('columns.settings.projectAccess')}</span>,
        Cell: function projectAccess({
          row: {
            original: { id, type, memberId, projectsAccessCount },
          },
        }: TableCell<CompanyUser>) {
          return isAdminPermission(type) ? (
            EMPTY_DATA_STATE
          ) : (
            <ProjectAccessCell id={id} memberId={memberId} projectsAccessCount={projectsAccessCount} />
          );
        },
      },
      {
        Header: t('columns.settings.dateCreation'),
        accessor: CREATION_DATE,
        Cell: function role({
          row: {
            original: { createdAt },
          },
        }: TableCell<CompanyUser>) {
          return <span className="text-secondary">{toShortFormat(new Date(createdAt))}</span>;
        },
      },
      {
        Header: ' ',
        accessor: ACTIONS,
        Cell({
          row: {
            original,
            original: {
              status,
              member: { is_pending },
            },
          },
          isHovered,
        }: TableCell<CompanyUser> & { isHovered?: boolean }) {
          const deactivateUserConfirmMessage = `${t('settings.confirmDeactivateUser')} ${
            original.reportingToCount > 1
              ? `\n${t('settings.warning')}: ${original.reportingToCount} ${pluralize(
                  t('settings.reportee'),
                  original.reportingToCount,
                )} ${t('settings.warningMessage')}`
              : ''
          }`;

          const isEditAccess =
            isAdmin(original) || isOwner(original)
              ? hasAccess(ActionsType.EditAdmins)
              : hasAccess(ActionsType.EditUsers);
          const isDeactivateAccess =
            isAdmin(original) || isOwner(original)
              ? hasAccess(ActionsType.DeactivateAdmins)
              : hasAccess(ActionsType.DeactivateUsers);
          const isRestoreAccess =
            isAdmin(original) || isOwner(original)
              ? hasAccess(ActionsType.RestoreAdmins)
              : hasAccess(ActionsType.RestoreUsers);

          const isShowMenu = !is_pending
            ? isEditAccess || isDeactivateAccess || isRestoreAccess
            : hasAccess(ActionsType.RefreshInvitations) || hasAccess(ActionsType.DeleteInvitations);

          if (!isShowMenu) {
            return null;
          }

          return (
            <TableActionCell isHovered={isHovered}>
              {!is_pending && isEditAccess && (
                <Tooltip title={t('actions.edit')!} placement="top">
                  <IconButton
                    className="editIconButton"
                    size="small"
                    disabled={isMe(original) || isOwner(original)}
                    onClick={() => push(link({ mode: ModalModeEnum.edit, userId: original.member.id }))}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {is_pending && (
                <>
                  {hasAccess(ActionsType.RefreshInvitations) && (
                    <Tooltip title={t('actions.resend')!} placement="top">
                      <IconButton
                        className="editIconButton"
                        size="small"
                        onClick={() => handleResendInvitation(original)}
                      >
                        <Resend />
                      </IconButton>
                    </Tooltip>
                  )}
                  {hasAccess(ActionsType.DeleteInvitations) && (
                    <Tooltip title={t('actions.cancel')!} placement="top">
                      <IconButton
                        className="removeIconButton"
                        size="small"
                        onClick={() => handleDeleteInvitation(original)}
                      >
                        <DeactivateUser />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              {!is_pending &&
                (status !== CompanyUserStatus.Deactivated
                  ? isDeactivateAccess && (
                      <ConfirmIconButton
                        tooltipText={t('actions.deactivate')}
                        onClick={() => handleDeactivateUser(original)}
                        isDelete
                        hideDeleteIcon
                        confirmTitle={t('settings.deactivateUser')}
                        confirmMessage={deactivateUserConfirmMessage}
                        confirmSubmitButtonTitle={t('settings.deactivateUser')}
                      >
                        <DeactivateUser />
                      </ConfirmIconButton>
                    )
                  : isRestoreAccess && (
                      <ConfirmIconButton
                        tooltipText={t('actions.restore')}
                        onClick={() => handleActivateUser(original.id)}
                        confirmTitle={t('settings.activateUser')}
                        confirmMessage={t('settings.confirmActivateUser')}
                        confirmSubmitButtonTitle={t('settings.activateUser')}
                      >
                        <RestoreUser />
                      </ConfirmIconButton>
                    ))}
            </TableActionCell>
          );
        },
      },
    ],
    [isActive, isPermissionsLoading],
  );

  if (!hasAccess(ActionsType.ViewUsers) || !hasAccess(ActionsType.ViewInvitations)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <div className="flex align-items-center justify-content-end mb-24">
        {hasAccess(ActionsType.InviteUsers) && (
          <Button
            className={styles.createButton}
            onClick={() => push(link({ mode: ModalModeEnum.create }))}
            startIcon={<PlusIcon />}
          >
            {t('settings.createNewUser')}
          </Button>
        )}
        <div className="flex align-items-center">
          <ResizableSearch onChange={setSearchTerm} onBlur={onSearchQueryUpdate} value={searchTerm} className="mr-12" />
          <span className={styles.switchLabel}>{t('settings.showArchived')}</span>
          <Switch
            checked={isActive}
            onChange={handleChange}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>

      <Table
        data={isActive ? allUsers : activeUsers}
        columns={columns as Column<CompanyUserDataFragment>[]}
        loading={(!users.length && loading) || invitationLoading}
      />

      <RightDrawer
        direction="right"
        open={params.mode === ModalModeEnum.create}
        onClose={onCloseCreateModal}
        title={t('settings.createNewUser')}
      >
        <NewUser onSubmit={handleNewUserSubmit} onCancel={onCloseCreateModal} />
      </RightDrawer>

      <RightDrawer direction="right" open={!!memberToEdit} onClose={onCloseEditModal} title={t('settings.editUser')}>
        <NewUser
          emailDisabled
          email={memberToEdit?.member.email}
          role={(memberToEdit?.type as unknown) as EditableMemberType}
          onSubmit={handleMemberEditSubmit}
          onCancel={onCloseEditModal}
          submitLabel={t('actions.save')}
        />
      </RightDrawer>
    </>
  );
};

export default Users;
